import type {MeasurementTrend} from '../../api/service-types';
import {StyledContainer} from './styles';
import iconDoubleUp from '../../assets/images/double_up.svg';
import iconDoubleDown from '../../assets/images/double_down.svg';
import iconUp from '../../assets/images/up.svg';
import iconDown from '../../assets/images/down.svg';
import iconDiagonalUp from '../../assets/images/diagonal_up.svg';
import iconDiagonalDown from '../../assets/images/diagonal_down.svg';
import iconRight from '../../assets/images/right.svg';

type Size = 12 | 16 | 18 | 24;

export type InsulinTrendIconProps = {
  trend: MeasurementTrend;
  size?: Size;
};

const widthMap: Record<Size, string> = {
  12: '20px',
  16: '26px',
  18: '28px',
  24: '40px',
};

const heightMap: Record<Size, string> = {
  12: '20px',
  16: '26px',
  18: '28px',
  24: '40px',
};

const getRoundedTrend = (trend: number) => {
  if (trend >= 3) return 3;
  if (trend >= 2 && trend < 3) return 2;
  if (trend >= 1 && trend < 2) return 1;
  if (Math.abs(trend) < 1) return 0;
  if (trend <= -2 && trend > -3) return -2;
  if (trend <= -1 && trend > -2) return -1;
  if (trend <= -3) return -3;
};

export const InsulinTrendIcon = ({trend, size = 24}: InsulinTrendIconProps): JSX.Element => {
  const roundedTrend = getRoundedTrend(trend) as keyof typeof iconMap;
  const iconMap: Record<MeasurementTrend, string> = {
    '3': iconDoubleUp,
    '2': iconUp,
    '1': iconDiagonalUp,
    '0': iconRight,
    '-1': iconDiagonalDown,
    '-2': iconDown,
    '-3': iconDoubleDown,
  };
  const selectedIcon = iconMap[roundedTrend] || iconRight;

  return (
    <StyledContainer
      width={widthMap[size]}
      height={heightMap[size]}
      background="transparent"
      sx={{
        marginRight: '0.5rem',
        marginLeft: '0.5rem',
      }}>
      <img src={selectedIcon} style={{width: '100%', height: 'auto'}} loading="lazy" />
    </StyledContainer>
  );
};
