export const getTimeDomain = (selectedDateRange: string, currentTime: number): [number, number] => {
  const timeFrameMap: {[key: string]: number} = {
    '1h': 1 * 60 * 60 * 1000,
    '3h': 3 * 60 * 60 * 1000,
    '6h': 6 * 60 * 60 * 1000,
    '8h': 8 * 60 * 60 * 1000,
    '10h': 10 * 60 * 60 * 1000,
    '12h': 12 * 60 * 60 * 1000,
    '16h': 16 * 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    'three-days': 3 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    'two-weeks': 14 * 24 * 60 * 60 * 1000,
    month: 30 * 24 * 60 * 60 * 1000,
    all: 60 * 24 * 60 * 60 * 1000, // 2 months (temporary value)
  };

  // default to '3h' if not found in the map
  const range = timeFrameMap[selectedDateRange] || timeFrameMap['3h'];

  const minX = currentTime - range;
  const maxX = currentTime;

  return [minX, maxX];
};
