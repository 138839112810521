import {isNil} from 'lodash';
import {useTheme, Typography, Tooltip} from '@mui/material';

import {Icon, InlineLink, InsulinTrendIcon} from '../../../components';
import type {Participant, Measurement} from '../../../api/service-types';
import {unitSelector} from '../../../store/reducers/unitSlice';
import {useAppSelector} from '../../../store';

import {
  StyledContainer,
  StyledContent,
  StyledLastUpdate,
  StyledTimeValue,
  StyledValueContainer,
  StyledValue,
  StyledOperationMode,
} from './styles';
import {mmolPerLToMgPerdL} from '../../../helpers/formulas';
import {DateTimeAgo} from '../../../utils';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faUserXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export type ParticipantCardProps = {
  participant: Participant;
  measurement?: Measurement;
};

export const ParticipantCard = ({participant, measurement}: ParticipantCardProps): JSX.Element => {
  const theme = useTheme();
  const {unit} = useAppSelector(unitSelector);
  const measurementValue = mmolPerLToMgPerdL(measurement?.measurement ?? 0, unit);

  //console.log('measurement', measurement);

  return (
    <InlineLink to={`/dashboard/participants/${participant.id}`}>
      <StyledContainer>
        <Typography variant="bodySmall" color={theme.palette.basic[800]}>
          #{participant.id}
        </Typography>
        <Typography variant="h3" color={theme.palette.basic[1000]}>
          {participant.name}
          <Tooltip title="Test Participant" arrow>
            <FontAwesomeIcon
              icon={faUserXmark as IconProp}
              size="lg"
              color="red"
              style={{marginLeft: '8px', cursor: 'pointer'}}
            />
          </Tooltip>
        </Typography>
        <StyledContent>
          <StyledLastUpdate>
            <Typography variant="bodySmall" color={theme.palette.basic[800]}>
              Last update:
            </Typography>
            <StyledTimeValue>
              {measurement?.eventLocalDateTime && (
                <Icon icon="check-rounded" size={20} color={theme.palette.primaryCustom[800]} />
              )}
              <Typography variant="bodyBig" color={theme.palette.basic[1000]}>
                {measurement ? DateTimeAgo(measurement.eventLocalDateTime) : 'null'}
              </Typography>
            </StyledTimeValue>
          </StyledLastUpdate>
          <StyledValueContainer>
            <StyledValue>
              <Typography fontWeight={700} fontSize="50px" lineHeight="52px" color={theme.palette.basic[1000]}>
                {measurementValue}
              </Typography>
              <Typography variant="h3" color={theme.palette.basic[1000]} padding="6.5px 0px">
                {unit}
              </Typography>
            </StyledValue>
            {!isNil(measurement) && <InsulinTrendIcon trend={measurement.trend} size={24} />}
          </StyledValueContainer>
        </StyledContent>
        <StyledOperationMode>
          <Icon icon="operation-mode" size={20} color={theme.palette.basic[300]} />
          <Typography fontWeight={400} fontSize="12px" lineHeight="16px" color={theme.palette.basic[800]}>
            {measurement?.controlMode ? measurement?.controlMode : 'null'}
          </Typography>
        </StyledOperationMode>
      </StyledContainer>
    </InlineLink>
  );
};
