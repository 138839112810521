import {BasalProfile, DynamicData} from '../../../api';
import {useRef, useEffect, useState, useMemo} from 'react';
import {scaleLinear, scaleTime} from '@visx/scale';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {Group} from '@visx/group';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import GridRows from '@visx/grid/lib/grids/GridRows';
import {curveStepAfter, line} from 'd3-shape';
import {FormControlLabel, Grid, Switch, useTheme} from '@mui/material';
import styled from '@emotion/styled';
import {Circle, LinePath} from '@visx/shape';
import moment from 'moment';

interface DataPoint {
  x: Date;
  y: number;
}

interface InsulinAndMealsChartProps {
  microboluses: DynamicData['microboluses'];
  crosshairTime: number | null;
  setCrosshairTime: (time: number | null) => void;
  basalProfiles: BasalProfile[];
  selectedDateRange: string;
  minX: number;
  maxX: number;
}

export const InsulinAndMealsChart = ({
  microboluses,
  crosshairTime,
  setCrosshairTime,
  selectedDateRange,
  basalProfiles,
  minX,
  maxX,
}: InsulinAndMealsChartProps): JSX.Element => {
  const [tooltipData, setTooltipData] = useState<DataPoint | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{x: number; y: number} | null>(null);
  const [width, setWidth] = useState(1000);
  const [isUnit, setIsUnit] = useState(true);
  const [tooltipTitle, setTooltipTitle] = useState<string>('');
  const theme = useTheme();

  const microbolusesData: DataPoint[] = microboluses.map(micro => ({
    x: new Date(micro.eventLocalDateTime),
    y: micro.basalSafetyNetAmount,
  }));

  const svgRef = useRef<SVGSVGElement>(null);
  const generateRepeatedBasalProfileData = () => {
    const repeatedData: DataPoint[] = [];

    for (let time = minX; time <= maxX; time += 5 * 60 * 1000) {
      const hourIndex = new Date(time).getHours();
      const value = parseFloat(basalProfiles[0].profile[hourIndex].toString());
      repeatedData.push({x: new Date(time), y: value});
    }

    const finalHourIndex = new Date(maxX).getHours();

    if (
      basalProfiles.length > 0 &&
      !basalProfiles[0]?.profile &&
      !basalProfiles[0].profile[Math.floor(finalHourIndex)] !== undefined
    ) {
      return;
    }

    const getSafeFinalValue = (profiles: Array<{profile: Array<number | undefined>}>, index: number): number => {
      if (profiles.length === 0 || !profiles[0]?.profile || profiles[0].profile[index] === undefined) {
        return 0;
      }
      return parseFloat(String(profiles[0].profile[index]));
    };

    const finalValue = getSafeFinalValue(basalProfiles, Math.floor(finalHourIndex));

    repeatedData.push({x: new Date(maxX), y: finalValue});

    return repeatedData;
  };

  const basalProfileData = generateRepeatedBasalProfileData();

  const minY = 0;
  const maxY = basalProfileData
    ? Math.max(...microbolusesData.map(d => d.y), ...basalProfileData.map(d => d.y))
    : Math.max(...microbolusesData.map(d => d.y));
  const height = 250;
  const margin = {top: 20, right: 20, bottom: 30, left: 40};

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const xScale = scaleTime({
    domain: [minX, maxX],
    range: [margin.left, width - margin.right],
  });

  const yScale = scaleLinear({
    domain: [minY, maxY],
    range: [height - margin.bottom, margin.top],
  });

  // y axis tick formatting
  const calculateYTicks = () => {
    const [yMin, yMax] = yScale.domain();
    const yTicks = yScale.ticks();
    const yTickDist = yTicks[yTicks.length - 1] - yTicks[yTicks.length - 2];
    const yTickPaddingTop = yTicks[yTicks.length - 1] + yTickDist - yMax;
    const yTickPaddingBottom = yMin - yTicks[0] + yTickDist;
    const yTickBottom = parseFloat((yMin - yTickPaddingBottom).toFixed(2));
    const yTickTop = parseFloat((yMax + yTickPaddingTop).toFixed(2));
    return [yTickBottom, yTickTop];
  };
  const [yTickBottom, yTickTop] = calculateYTicks();
  yScale.domain([yTickBottom, yTickTop]);

  // generate default ticks
  const defaultTicks = useMemo(() => {
    return xScale.ticks();
  }, [xScale]);
  // adjust tick number based on screen width and default tick count
  const numTicks = useMemo(() => {
    let tickCount = defaultTicks.length;
    if (width <= 575 && tickCount >= 14) {
      tickCount = Math.ceil(tickCount / 2);
    } else if (width <= 490 && tickCount >= 8) {
      tickCount = Math.ceil(tickCount / 2);
    }
    if (tickCount < 6) {
      tickCount = defaultTicks.length;
    }
    return tickCount;
  }, [width, defaultTicks]);

  const lineGenerator = line<DataPoint>()
    .x(d => xScale(d.x))
    .y(d => yScale(d.y))
    .curve(curveStepAfter);

  const handleMouseMove = (event: React.MouseEvent<SVGSVGElement>) => {
    const svgRect = svgRef.current?.getBoundingClientRect();
    if (!svgRect) return;

    const mouseXPosition = event.clientX - svgRect.left;
    const mouseYPosition = event.clientY - svgRect.top;
    const timeAtMousePosition = xScale.invert(mouseXPosition).getTime();

    const closestMicrobolus = microbolusesData.reduce((prev, curr) => {
      const prevDistance = Math.abs(xScale(prev.x) - mouseXPosition);
      const currDistance = Math.abs(xScale(curr.x) - mouseXPosition);
      return currDistance < prevDistance ? curr : prev;
    });

    const closestBasalProfile =
      basalProfileData &&
      basalProfileData.reduce((prev, curr) => {
        const prevDistance = Math.abs(xScale(prev.x) - mouseXPosition);
        const currDistance = Math.abs(xScale(curr.x) - mouseXPosition);
        return currDistance < prevDistance ? curr : prev;
      });

    let closestDatum;
    if (closestBasalProfile) {
      // scale if necessary U-> U/h
      const closestMicrobolusYPosition = isUnit ? yScale(closestMicrobolus.y) : yScale(closestMicrobolus.y / 12);
      const closestBasalProfileYPosition = isUnit ? yScale(closestBasalProfile.y) : yScale(closestBasalProfile.y / 12);
      // calculate distance between mouse's y position and y value of closest data point
      const microbolusYDistance = Math.abs(mouseYPosition - closestMicrobolusYPosition);
      const basalProfileYDistance = Math.abs(mouseYPosition - closestBasalProfileYPosition);
      // determine which is closer to mouse's y position
      closestDatum = microbolusYDistance < basalProfileYDistance ? closestMicrobolus : closestBasalProfile;
    } else {
      closestDatum = closestMicrobolus;
    }

    const tooltipTitle = closestDatum === closestMicrobolus ? 'Microbolus' : 'Basal';
    // if data out of bound, set crosshair to null (basalprofile)
    if (timeAtMousePosition < minX || timeAtMousePosition > maxX) {
      setCrosshairTime(null);
      setTooltipData(null);
      return;
    }

    const tooltipXPos = Math.min(Math.max(mouseXPosition + 1, margin.left + 1), width - margin.right - 100);
    const tooltipYPos = Math.min(
      Math.max((isUnit ? yScale(closestDatum.y) : yScale(closestDatum.y / 12)) - 80),
      height - margin.bottom - 1,
    );

    setTooltipTitle(tooltipTitle);
    setCrosshairTime(timeAtMousePosition);

    setTooltipData(closestDatum);
    setTooltipPosition({
      x: tooltipXPos,
      y: tooltipYPos,
    });
  };

  const handleMouseLeave = () => {
    setTooltipData(null);
    setTooltipPosition(null);
    setCrosshairTime(null);
  };

  const ChartLabel = styled('h5')(() => ({
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0,
  }));

  // function to modify data if unit U
  const modifyDataForUnit = (data: DataPoint[]): DataPoint[] => {
    const maxY = Math.max(
      ...microbolusesData.map(d => d.y / 12),
      ...(basalProfileData ? basalProfileData.map(d => d.y / 12) : []),
    );
    const minY = Math.min(
      ...microbolusesData.map(d => d.y / 12),
      ...(basalProfileData ? basalProfileData.map(d => d.y / 12) : []),
    );
    // y axis tick formatting
    yScale.domain([minY, maxY]);
    const [yTickBottom, yTickTop] = calculateYTicks();
    yScale.domain([yTickBottom, yTickTop]);

    return data.map(d => ({...d, y: d.y / 12}));
  };
  // responsive circle size
  const circleSize = selectedDateRange === '1h' || selectedDateRange === '3h' ? 3 : 2;

  // helper function to split data into segments if data is missing
  const splitDataIntoSegments = (data: DataPoint[], threshold: number = 5 * 60 * 1000): DataPoint[][] => {
    const segments: DataPoint[][] = [];
    let currentSegment: DataPoint[] = [];
    const reversedData = [...data].reverse();

    for (let i = 0; i < reversedData.length; i++) {
      const point = reversedData[i];

      // if currentSegment is empty/ gap between point and it's predecessor is within the threshold
      if (
        currentSegment.length === 0 ||
        point.x.getTime() - currentSegment[currentSegment.length - 1].x.getTime() <= threshold
      ) {
        currentSegment.push(point);
      } else {
        // if time gap too large, push current segment and start a new one
        segments.push(currentSegment);
        currentSegment = [point];
      }
    }

    // push last segment if it contains any points
    if (currentSegment.length > 0) {
      segments.push(currentSegment);
    }
    return segments.reverse();
  };
  // split data into segments based on missing data
  const microbolusesDataFiltered = useMemo(() => splitDataIntoSegments(microbolusesData), [microbolusesData]);

  return (
    <div>
      <Grid container xs={12} spacing={2} mt={2}>
        <Grid xs={0.5} display={'flex'} padding={0} justifyContent="flex-end" alignItems="center" marginLeft="auto">
          <ChartLabel
            style={{
              position: 'absolute',
              writingMode: 'vertical-lr',
              transform: 'rotate(180deg)',
              marginLeft: margin.bottom,
            }}>
            {isUnit ? 'U/h' : 'U'}
          </ChartLabel>
        </Grid>
        <Grid xs={11.5}>
          <FormControlLabel
            control={<Switch checked={isUnit} onChange={() => setIsUnit(!isUnit)} />}
            label={isUnit ? 'U/h' : 'U'}
          />
          <div style={{position: 'relative'}}>
            <svg
              ref={svgRef}
              width="100%"
              height={height}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              pointerEvents="all">
              <defs>
                <linearGradient
                  id="microbolus-gradient"
                  x1="0"
                  y1="0"
                  x2="1"
                  y2="0"
                  spreadMethod="pad"
                  gradientTransform={`translate(${margin.left}, 0) scale(${width - margin.left - margin.right}, 1)`}>
                  <stop offset="0%" stopColor="#03D4FD" />
                  <stop offset="100%" stopColor="#0AD3CD" />
                </linearGradient>
              </defs>

              <rect width={width} height={height} rx={14} fill={'#fff'} />

              <Group>
                {/* Grid */}
                <GridColumns
                  scale={xScale}
                  top={margin.top}
                  height={height - margin.bottom - margin.top}
                  stroke="#e0e0e0"
                  strokeDasharray="5,5"
                  pointerEvents="none"
                />
                <GridRows
                  scale={yScale}
                  width={width - margin.right - margin.left}
                  strokeDasharray="4"
                  left={margin.left}
                />
                <AxisBottom
                  top={height - margin.bottom}
                  scale={xScale}
                  numTicks={numTicks}
                  tickLabelProps={() => ({
                    fill: 'black',
                    fontSize: 11,
                    textAnchor: 'middle',
                  })}
                  tickFormat={d => {
                    const date = d instanceof Date ? d : new Date(d.valueOf());
                    return date.toLocaleTimeString('en-EU', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    });
                  }}
                />
                <AxisLeft
                  scale={yScale}
                  left={margin.left}
                  tickLabelProps={() => ({
                    fill: 'black',
                    fontSize: 11,
                    textAnchor: 'end',
                  })}
                />
              </Group>

              {/* Microbolus */}
              {microbolusesDataFiltered.map((segment, index) => (
                <LinePath
                  key={`microbolus-segment-${index}`}
                  d={lineGenerator(isUnit ? segment : modifyDataForUnit(segment)) || undefined}
                  fill="none"
                  stroke="url(#microbolus-gradient)"
                  strokeWidth={2}
                />
              ))}
              {isUnit
                ? microbolusesData.map((d, i) => (
                    <Circle
                      key={`microbolus-dot-${i}`}
                      cx={xScale(d.x)}
                      cy={yScale(d.y)}
                      r={circleSize}
                      fill="#03D4FD"
                    />
                  ))
                : modifyDataForUnit(microbolusesData).map((d, i) => (
                    <Circle
                      key={`microbolus-dot-${i}`}
                      cx={xScale(d.x)}
                      cy={yScale(d.y)}
                      r={circleSize}
                      fill="#03D4FD"
                    />
                  ))}

              {/* Basal profile */}
              {basalProfileData && (
                <LinePath
                  d={lineGenerator(isUnit ? basalProfileData : modifyDataForUnit(basalProfileData)) || undefined}
                  fill="none"
                  stroke="#8B8C89"
                  strokeWidth={2}
                />
              )}

              {/* Crosshair */}
              {crosshairTime !== null && (
                <line
                  x1={xScale(new Date(crosshairTime))}
                  x2={xScale(new Date(crosshairTime))}
                  y1={margin.top}
                  y2={height - margin.bottom}
                  stroke="gray"
                  strokeDasharray="4,4"
                />
              )}

              {tooltipData && (
                <Circle
                  cx={xScale(tooltipData.x)}
                  cy={isUnit ? yScale(tooltipData.y) : yScale(tooltipData.y / 12)}
                  r={3}
                  fill="black"
                />
              )}
            </svg>

            {/* Tooltip */}
            {tooltipData && tooltipPosition && (
              <div
                style={{
                  position: 'absolute',
                  top: tooltipPosition.y,
                  left: tooltipPosition.x,
                  backgroundColor: '#fff',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                  justifyContent: 'center',
                  textAlign: 'center',
                  zIndex: 9999,
                  pointerEvents: 'none',
                  minWidth: '111px',
                }}>
                <h5 style={{margin: 0, padding: 0, color: theme.palette.customColors?.lightGrey || 'black'}}>
                  {tooltipTitle}
                </h5>
                <p style={{fontWeight: 'bold', fontSize: '14px', margin: 0, padding: 0}}>
                  {isUnit ? `${tooltipData.y.toFixed(2)} U/h` : `${(tooltipData.y / 12).toFixed(2)} U`}
                </p>
                <p
                  style={{
                    fontSize: '12px',
                    margin: 0,
                    padding: 0,
                  }}>
                  {moment(tooltipData.x).format('DD/MM/YY HH:mm')}
                </p>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
