import {createApi, BaseQueryFn} from '@reduxjs/toolkit/query/react';

import {wsService} from './ws.service';
import {httpsUrl} from '../../constants';
import {baseQuery} from '../../helpers';
import {EventComment, EventCommentMutationCreate, EventsForClinicalTrialsResponse, EventUpdate} from '../service-types';
import type {IdParams} from '../query-types';

const eventsApiBuilder = (baseQuery: BaseQueryFn) =>
  createApi({
    reducerPath: 'events-service',
    tagTypes: ['EVENT'],
    baseQuery,
    endpoints: builder => ({
      getEventsByClinicalTrialId: builder.query<EventsForClinicalTrialsResponse, {params: IdParams}>({
        query: ({params}) => ({
          url: `/event-notifications?clinical_trial_id=${params.id}&limit=25`,
          method: 'GET',
        }),
        async onCacheEntryAdded(_params, {updateCachedData, cacheDataLoaded, cacheEntryRemoved}) {
          try {
            await cacheDataLoaded;

            wsService.listener(nextEvent => {
              updateCachedData(draft => {
                draft.data.push(nextEvent);
              });
            });
          } catch (e) {
            console.log('cacheDataLoaded resolve', e);
          }

          await cacheEntryRemoved;
        },

        providesTags: result =>
          result
            ? [...result.data.map(({id}) => ({type: 'EVENT' as const, id})), {type: 'EVENT', id: 'LIST'}]
            : [{type: 'EVENT', id: 'LIST'}],
      }),
      updateEvent: builder.mutation<void, {body: EventUpdate}>({
        query: ({body}) => ({
          url: '/event-notifications',
          method: 'PATCH',
          body,
        }),
        invalidatesTags: [{type: 'EVENT', id: 'LIST'}],
      }),

      getEventCommentsByThreadId: builder.query<EventComment[], {params: IdParams}>({
        query: ({params}) => ({
          url: `/event-notifications/comments?${params.id}`,
          method: 'GET',
        }),
        providesTags: (_result, _error, {params}) => [{type: 'EVENT', id: params.id}],
      }),
      createEventComment: builder.mutation<void, {body: EventCommentMutationCreate}>({
        query: ({body}) => ({
          url: '/event-notifications/comments',
          method: 'POST',
          body,
        }),
        invalidatesTags: (_result, _error, {body}) => [{type: 'EVENT', id: body.threadId}],
      }),
      deleteEventComment: builder.mutation<void, {commentId: number}>({
        query: ({commentId}) => ({
          url: `/event-notifications/comments/${commentId}`,
          method: 'DELETE',
          commentId,
        }),
        // invalidatesTags: (_result, _error, {body}) => [{type: 'EVENT', id: body.threadId}],
      }),
      editEventComment: builder.mutation<void, {body: EventComment}>({
        query: ({body}) => ({
          url: '/event-notifications/comments',
          method: 'PATCH',
          body,
        }),
        invalidatesTags: [{type: 'EVENT', id: 'LIST'}],
      }),
    }),
  });

export const eventsService = eventsApiBuilder(baseQuery(httpsUrl));
